import maplibregl from 'maplibre-gl';

import { loadParkingSlotsInCurrentView, clearParkingSlotData } from '@/prken_land/mapUtils';

export const onMapClickCallback = (event, mapInstance) => {
  // Query features at the clicked point.
  const features = mapInstance.queryRenderedFeatures(event.point, {
    layers: ['observations', 'parking-slots', 'parking-slots-pay'],
  });

  // Early return if no features are found.
  if (features.length === 0) {
    return;
  }

  // Get the clicked layer.
  const clickedLayer = features[0].layer.id;
  console.log(`Clicked on layer: ${clickedLayer}`);

  // Handle the click event based on the layer
  switch (clickedLayer) {
    case 'observations': {
      handleObservationClick(features[0], mapInstance);
      break;
    }
    case 'parking-slots': {
      handleParkingSlotClick('parking-slots', features[0], mapInstance);
      break;
    }
    case 'parking-slots-pay': {
      handleParkingSlotClick('parking-slots-pay', features[0], mapInstance);
      break;
    }
  }
};

const handleObservationClick = (observationFeature, mapInstance) => {
  console.log('Observation clicked');

  new maplibregl.Popup()
    .setLngLat(observationFeature.geometry.coordinates)
    .setHTML(
      `${observationFeature.properties.title}`
    )
    .setOffset([0, -15])
    .addTo(mapInstance);
};

const handleParkingSlotClick = (layerId, parkingSlotFeature, mapInstance) => {
  console.log('Parking slot clicked for layer: ' + layerId);

  new maplibregl.Popup()
    .setLngLat(parkingSlotFeature.geometry.coordinates)
    .setHTML(
      `${parkingSlotFeature.properties.art}, ${parkingSlotFeature.properties.gebuehrenpflichtig}. Parkdauer: ${parkingSlotFeature.properties.parkdauer}`
    )
    .setOffset([0, -30])
    .addTo(mapInstance);
};

export const onMapMoveEndCallback = async (mapInstance) => {
  if (mapInstance.getZoom() < 15) {
    clearParkingSlotData(mapInstance);
    return;
  }

  await loadParkingSlotsInCurrentView(mapInstance);
}