import maplibregl from 'maplibre-gl';

import parkingSignImage from '@/assets/parking-sign-small.png';
import parkingSignImagePay from '@/assets/parking-sign-small-pay.png';
import { queryObservationsOnDayN } from '@/observations/api';
import { heatmapTakenStyle, heatmapFreeStyle, avatarLayerStyle } from '@/prken_land/mapLibreConfig';
import { getAvatars } from '@/avatars/loader';
import { addTitleToObservation } from '@/observations/visualize';
import { queryParkingSlots } from '@/parking_slots/api';


/**
 * Creates and initializes a map instance using MapLibre GL.
 *
 * @param {Object} mapContainerRef - A reference to the DOM element that will contain the map.
 * @param {Object} mapContainerRef.value - The actual DOM element.
 * @returns {Object} The initialized map instance.
 */
export const createMap = (mapContainerRef) => {
  const mapInstance = new maplibregl.Map({
    container: mapContainerRef.value, // DOM element reference
    style: 'https://tiles.stadiamaps.com/styles/alidade_smooth.json', // Example style
    center: [8.5417, 47.3769], // ZH coordinates
    zoom: 10 // starting zoom
  });

  // Add location control .
  mapInstance.addControl(
    new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    }), 'bottom-right'
  );

  // Add basic map navigation control.
  mapInstance.addControl(new maplibregl.NavigationControl(), 'bottom-right');

  return mapInstance;
};


export const initializePrkenMap = async (mapInstance) => {
  console.log('Initializing map...');
  // Load avatar images from backend and add them as available images to the map.
  await loadAvatarImages(mapInstance);

  // Load observations of today from the backend and add them to the map.
  await loadObservations(mapInstance);

  // Prepare and add parking slot layers to the map. 
  await loadParkingSlots(mapInstance);

  // Initialize confetti layer for the confetti animation.
  initializeConfettiLayer(mapInstance);
};

const loadAvatarImages = async (mapInstance) => {
  // Load avatar images from backend and add them to the map.
  const avatars = await getAvatars();
  console.log('Loaded avatars:', avatars);
  for (let key in avatars) {
    console.log('Loading image:', avatars[key].small_url);
    const image = await mapInstance.loadImage(avatars[key].small_url);
    console.log('Adding image to map:', key);
    mapInstance.addImage(key, image.data);
  }
};

const loadObservations = async (mapInstance) => {
  // Query observations for the current day and add them to the map.
  const queryToday = new Date();
  queryToday.setHours(0, 0, 0, 0);
  const observations = await queryObservationsOnDayN(queryToday);

  // Add title to each observation.
  observations.forEach((observation) => {
    addTitleToObservation(observation);
  });

  // Add initial observation source to the map. 
  mapInstance.addSource('observations', {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
      'features': observations
    }
  });

  // Add layers to observations data source.
  mapInstance.addLayer(heatmapTakenStyle('observations'));
  mapInstance.addLayer(heatmapFreeStyle('observations'));
  mapInstance.addLayer(avatarLayerStyle('observations'));
};

const loadParkingSlots = async (mapInstance) => {
  // Initial set is empty since we dynamically load the parking slots based on the map bounds.
  mapInstance.addSource('parking-slots', {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
      'features': []
    }
  });

  // Prepare parking sign icon from assets. 
  const imageParkingSign = await mapInstance.loadImage(parkingSignImage);
  const imageParkingSignPay = await mapInstance.loadImage(parkingSignImagePay);
  mapInstance.addImage('parking-sign', imageParkingSign.data);
  mapInstance.addImage('parking-sign-pay', imageParkingSignPay.data);

  // Add layers for parking slots.
  mapInstance.addLayer({
    id: 'parking-slots',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'gebuehrenpflichtig'], 'nicht gebührenpflichtig'], // Only free slots
    layout: {
      'icon-image': 'parking-sign',
      'icon-size': 1.0,
    }
  });
  mapInstance.addLayer({
    id: 'parking-slots-pay',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'gebuehrenpflichtig'], 'gebührenpflichtig'], // Only paid slots
    layout: {
      'icon-image': 'parking-sign-pay',
      'icon-size': 1.0,
    }
  });
  mapInstance.addLayer({
    id: 'parking-slots-openstreetmap',
    type: 'symbol',
    source: 'parking-slots',
    filter: ['==', ['get', 'amenity'], 'parking'], // Openstreemap parking spots
    layout: {
      'icon-image': 'parking-sign',
      'icon-size': 1.0,
    }
  });
};

const initializeConfettiLayer = (mapInstance) => {
  mapInstance.addSource('confetti', {
    type: 'canvas',
    canvas: 'confetti-canvas', // The ID of the canvas element needs to exist.
    coordinates: [
      [8.492088, 47.399860], // Top-left corner [lng, lat]
      [8.598518, 47.399860], // Top-right corner [lng, lat]
      [8.598518, 47.335798], // Bottom-right corner [lng, lat]
      [8.492088, 47.335798], // Bottom-left corner [lng, lat]
    ],
    animate: true,
  });

  // Add the canvas as a MapLibre layer
  mapInstance.addLayer({
    id: 'confetti-layer',
    type: 'raster',
    source: 'confetti',
  });
};

export const loadParkingSlotsInCurrentView = async (mapInstance) => {
  const bounds = mapInstance.getBounds();
  const parkingSlots = await queryParkingSlots(bounds);
  console.log(`Received ${parkingSlots.length} parking slots from backend`);
  console.log(parkingSlots);
  mapInstance.getSource('parking-slots').setData({
    'type': 'FeatureCollection',
    'features': parkingSlots
  });
};

export const clearParkingSlotData = (mapInstance) => {
  mapInstance.getSource('parking-slots').setData({
    'type': 'FeatureCollection',
    'features': []
  });
};

export const addObservationsToMap = (observations, mapInstance) => {
  observations.forEach((observation) => {
    addTitleToObservation(observation);
  });

  mapInstance.getSource('observations').setData({
    'type': 'FeatureCollection',
    'features': observations
  });
};

const addObservationToMap = (observation, mapInstance) => {
  addTitleToObservation(observation);
  const currentData = mapInstance.getSource('observations')._data;
  currentData.features.push(observation);
  mapInstance.getSource('observations').setData(currentData);
};

export const updateObservationsLayerVisibility = (mapInstance, visibility) => {
  mapInstance.setLayoutProperty('observations', 'visibility', visibility.avatars ? 'visible' : 'none');
  mapInstance.setLayoutProperty('heatmap-taken', 'visibility', visibility.taken ? 'visible' : 'none');
  mapInstance.setLayoutProperty('heatmap-free', 'visibility', visibility.free ? 'visible' : 'none');
};

/**
 * Updates the map instance based on the given observation.
 * Specifically, it updates the coordinates of the confetti source
 * to match the current map bounds and adds the observation to the map.
 *
 * @param {Object} observation - The observation data to be added to the map.
 * @param {Object} mapInstance - The map instance to be updated.
 */
export const updateMapOnObservation = (observation, mapInstance) => {
  // Update where the confetti is emitted from.
  let confettiSource = mapInstance.getSource('confetti');
  const bounds = mapInstance.getBounds();
  // console.log(`bounds ${bounds}`);
  confettiSource.setCoordinates([
    // set coordinates based on current map bounds
    [bounds.getWest(), bounds.getNorth()],
    [bounds.getEast(), bounds.getNorth()],
    [bounds.getEast(), bounds.getSouth()],
    [bounds.getWest(), bounds.getSouth()]
  ]);

  addObservationToMap(observation, mapInstance);
};